

const PageError404 = ()=> {

    return(
        <section>
            <h1>Page Error 404</h1>
        </section>
    )
}

export default PageError404;